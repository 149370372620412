"use strict"

import { grey } from "@material-ui/core/colors"
import { createMuiTheme } from "@material-ui/core/styles"

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    /*
    primary: {
      main: `#556cd6`,
    },

    secondary: {
      main: `#19857b`,
    },

    error: {
      main: red.A400,
    },
    */
    common: {
      black: `#222`,
      white: `#FEFEFE`,
    },

    background: {
      default: grey.A100,
    },
  },

  typography: {
    fontFamily: [`Noto Sans JP`, `Lato`, `Roboto`, `sans`].join(`,`),

    body2: {
      fontSize: `0.8rem`,
      lineHeight: 1.9,
    },
  },

  width: {
    logo: 229,
    drawer: 307,
    breakpoint: 414,
  },

  prime: {
    sp002: 2,
    sp003: 3,
    sp005: 5,
    sp007: 7,
    sp011: 11,
    sp013: 13,
    sp017: 17,
    sp019: 19,
    sp023: 23,
    sp029: 29,
    sp031: 31,
    sp037: 37,
    sp041: 41,
    sp043: 43,
    sp047: 47,
    sp053: 53,
    sp059: 59,
    sp061: 61,
    sp067: 67,
    sp071: 71,
    sp073: 73,
    sp079: 79,
    sp083: 83,
    sp089: 89,
    sp097: 97,
    sp101: 101,
    sp103: 103,
    sp107: 107,
    sp109: 109,
    sp113: 113,
    sp127: 127,
    sp131: 131,
    sp137: 137,
    sp139: 139,
    sp149: 149,
    sp151: 151,
    sp157: 157,
    sp163: 163,
    sp167: 167,
    sp173: 173,
    sp179: 179,
    sp181: 181,
    sp191: 191,
    sp193: 193,
    sp197: 197,
    sp199: 199,
    sp211: 211,
    sp223: 223,
    sp227: 227,
    sp229: 229,
    sp233: 233,
    sp239: 239,
    sp241: 241,
    sp251: 251,
    sp257: 257,
    sp263: 263,
    sp269: 269,
    sp271: 271,
    sp277: 277,
    sp281: 281,
    sp283: 283,
    sp293: 293,
    sp307: 307,
    sp311: 311,
    sp313: 313,
    sp317: 317,
    sp331: 331,
    sp337: 337,
    sp347: 347,
    sp349: 349,
    sp353: 353,
    sp359: 359,
    sp367: 367,
    sp373: 373,
    sp379: 379,
    sp383: 383,
    sp389: 389,
    sp397: 397,
    sp401: 401,
    sp409: 409,
    sp419: 419,
    sp421: 421,
    sp431: 431,
    sp433: 433,
    sp439: 439,
    sp443: 443,
    sp449: 449,
    sp457: 457,
    sp461: 461,
    sp463: 463,
    sp467: 467,
    sp479: 479,
    sp487: 487,
    sp491: 491,
    sp499: 499,
    sp503: 503,
    sp509: 509,
    sp521: 521,
    sp523: 523,
    sp541: 541,
    sp547: 547,
    sp557: 557,
    sp563: 563,
    sp569: 569,
    sp571: 571,
    sp577: 577,
    sp587: 587,
    sp593: 593,
    sp599: 599,
    sp719: 719,
    sp953: 953,
  },

  /* Borrowed from http://colours.neilorangepeel.com */
  colors: [
    `aliceblue`,
    `antiquewhite`,
    `aqua`,
    `aquamarine`,
    `azure`,
    `beige`,
    `bisque`,
    `black`,
    `blanchedalmond`,
    `blue`,
    `blueviolet`,
    `brown`,
    `burlywood`,
    `cadetblue`,
    `chartreuse`,
    `chocolate`,
    `coral`,
    `cornflowerblue`,
    `cornsilk`,
    `crimson`,
    `cyan`,
    `darkblue`,
    `darkcyan`,
    `darkgoldenrod`,
    `darkgray`,
    `darkgreen`,
    `darkgrey`,
    `darkkhaki`,
    `darkmagenta`,
    `darkolivegreen`,
    `darkorange`,
    `darkorchid`,
    `darkred`,
    `darksalmon`,
    `darkseagreen`,
    `darkslateblue`,
    `darkslategray`,
    `darkslategrey`,
    `darkturquoise`,
    `darkviolet`,
    `deeppink`,
    `deepskyblue`,
    `dimgray`,
    `dimgrey`,
    `dodgerblue`,
    `firebrick`,
    `floralwhite`,
    `forestgreen`,
    `fuchsia`,
    `gainsboro`,
    `ghostwhite`,
    `gold`,
    `goldenrod`,
    `gray`,
    `green`,
    `greenyellow`,
    `grey`,
    `honeydew`,
    `hotpink`,
    `indianred`,
    `indigo`,
    `ivory`,
    `khaki`,
    `lavender`,
    `lavenderblush`,
    `lawngreen`,
    `lemonchiffon`,
    `lightblue`,
    `lightcoral`,
    `lightcyan`,
    `lightgoldenrodyellow`,
    `lightgray`,
    `lightgreen`,
    `lightgrey`,
    `lightpink`,
    `lightsalmon`,
    `lightseagreen`,
    `lightskyblue`,
    `lightslategray`,
    `lightslategrey`,
    `lightsteelblue`,
    `lightyellow`,
    `lime`,
    `limegreen`,
    `linen`,
    `magenta`,
    `maroon`,
    `mediumaquamarine`,
    `mediumblue`,
    `mediumorchid`,
    `mediumpurple`,
    `mediumseagreen`,
    `mediumslateblue`,
    `mediumspringgreen`,
    `mediumturquoise`,
    `mediumvioletred`,
    `midnightblue`,
    `mintcream`,
    `mistyrose`,
    `moccasin`,
    `navajowhite`,
    `navy`,
    `oldlace`,
    `olive`,
    `olivedrab`,
    `orange`,
    `orangered`,
    `orchid`,
    `palegoldenrod`,
    `palegreen`,
    `paleturquoise`,
    `palevioletred`,
    `papayawhip`,
    `peachpuff`,
    `peru`,
    `pink`,
    `plum`,
    `powderblue`,
    `purple`,
    `rebeccapurple`,
    `red`,
    `rosybrown`,
    `royalblue`,
    `saddlebrown`,
    `salmon`,
    `sandybrown`,
    `seagreen`,
    `seashell`,
    `sienna`,
    `silver`,
    `skyblue`,
    `slateblue`,
    `slategray`,
    `slategrey`,
    `snow`,
    `springgreen`,
    `steelblue`,
    `tan`,
    `teal`,
    `thistle`,
    `tomato`,
    `turquoise`,
    `violet`,
    `wheat`,
    `white`,
    `whitesmoke`,
    `yellow`,
    `yellowgreen`,
  ],
})

export default theme
